<template>
    <div class="col-xl-2 col-lg-3 col-md-12 sidebar position-relative" :class="{ toggled: toggled }">
        <div class="sidebar-header">
            <div class="logo">
                <img src='../../public/img/logo/mobs_agro.svg' alt="Mobs2 Agro - Logo">
            </div>
            <div class="position-absolute top-50 translate-middle menu-toggler">
                <MaterialIcon :icon="'menu'" @click="toggleSidebar()" />
            </div>
        </div>
        <div class="sidebar-selector mt-4">
            <select class="organization-selector form-select">
                <option selected>Selecione empresa</option>
                <option value="1">Organização 1</option>
                <option value="2">Organização 2</option>
                <option value="3">Organização 3</option>
            </select>
        </div>
        <div class="sidebar-nav mt-4">
            <nav class="navbar navbar-expand-lg bg-body-tertiary">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <MaterialIcon :icon="'menu'" />
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page"
                                href="/agro/cadastro/unidades_organizacionais">
                                <MaterialIcon :icon="'app_registration'" />
                                Cadastro
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">
                                <MaterialIcon :icon="'important_devices'" />
                                Monitoramento
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">
                                <MaterialIcon :icon="'print'" />
                                Relatório
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        <div class="sidebar-footer position-absolute bottom-0">
            <div class="logout-button fw-bold">
                <MaterialIcon :icon="'logout'" /> Sair
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import MaterialIcon from '../misc/material-icon.vue';

const props = defineProps({
    permissions: { type: String },
})

let toggled = ref(false);

const toggleSidebar = () => {
    toggled.value = !toggled.value;
}
</script>