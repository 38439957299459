<template>
    <div class="friendly-notification" :class="[props.options.type]">
        <span>
            {{ options.text }}
        </span>
    </div>
</template>
<script setup lang="ts">

const props = defineProps({
    options: { type: Object, required: true },
})
</script>
<style scoped>
.friendly-notification{
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    z-index: 101;
    max-width: 350px;
    padding: 1rem;
    border-radius: .5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    box-shadow: -.125rem -.125rem .5rem .125rem rgba(0, 0, 0, .125);
    transition: all .3s;
}
@media screen and (max-width: 991px){
    .friendly-notification{
        bottom: 1rem;
        left: 50%;
        font-size: 1rem;
        line-height: 1.3rem;
        transform: translate(-50%, 0);
        width: 90%;
        box-shadow: 0 1rem .5rem .125rem rgba(0, 0, 0, .5);
        text-align: center;
    }
}
.friendly-notification.success{
    background-color: #27ae60;
}
.friendly-notification.warning{
    background-color: #f39c12;
}
.friendly-notification.error{
    background-color: #c0392b;
}
</style>