<template>
    <div class="table-responsive">
        <table class="table table-borderless" spacing="10px">
            <thead>
                <tr>
                    <th scope="col" v-for="(header, index) in props.tableHeaders" :key="`header_${index}`">
                        {{ header }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <slot></slot>
            </tbody>
        </table>
    </div>
</template>
<script setup lang="ts">

const props = defineProps({
    tableHeaders: { type: Array<string[]>, required: true },
})

</script>
