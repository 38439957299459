<template>
  <div class='notranslate'>
    <VueDatePicker 
      v-model="date" 
      v-bind="dataPickerOptions"
      @update:model-value="handleDate"
      uid="-datepicker"
      :disabled="pickerMode == 'semester'"
      :format="format"
      :week-start="0" 
      >
        <template #input-icon>
            <material-icon :icon="'calendar_month'"></material-icon>
        </template>
    </VueDatePicker>
  </div>
  
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import { ptBR, enUS, es } from 'date-fns/locale';
import moment from "moment";
import '@vuepic/vue-datepicker/dist/main.css'
import { default as MaterialIcon } from "./material-icon.vue";

const emit = defineEmits(['pickedDate'])
const date = ref(null);

const handleDate = async (modelData) => {
  date.value = modelData;
  emit('pickedDate', modelData)
}

handleDate(new Date());

const props = defineProps({
  pickerMode: {
    type: null,
    default: 'day',
  },
  range: {
    type: Boolean,
    default: false,
    validator: value => [true,false].includes(value)
  },
  multiCalendars: {
    type: Boolean,
    default: false,
    validator: value => [true,false].includes(value)
  },
  locale: {
    type: String,
    default: 'pt',
    validator: value => ['pt', 'en', 'es'].includes(value)
  },
  autoApply:{
    type: Boolean,
    default: true,
    validator: value => [true,false].includes(value)
  },
});

const pickedMode = computed(() => props.pickerMode);

watch(() => pickedMode.value, (newValue) => {
  if(newValue == 'day'){
    handleDate(new Date());
  }else if(newValue == 'week'){
    handleDate([moment().add(-6, 'day')._d, moment()._d]);
  }else if(newValue == 'semester'){
    handleDate([
      moment().startOf('month').add(-5, 'month')
        .startOf('month').format('YYYY-MM-DD')._d, 
        moment().format('YYYY-MM-DD')._d
    ]);
  }
});

const placeholder = ref(null);

const format = (datePicked) => {  
  let formatDate = '';
  if(props.pickerMode == 'day'){
    formatDate =  moment(date.value).format('DD/MM/YYYY');
  }else if(props.pickerMode == 'week'){
    let dates = [];
    
    
    if(date.value.length !== undefined){
      for(const dateItem of date.value){
        dates.push(moment(dateItem).format('DD/MM/YYYY'))
      }
    }else{
      dates.push(moment(date.value).add(-6,'day').format('DD/MM/YYYY'))
      dates.push(moment(date.value).format('DD/MM/YYYY'))
    }
    formatDate = dates.join(' à ');
  }else if(props.pickerMode == 'semester'){
    placeholder.value = `${moment().startOf('month').add(-5, 'month').format('DD/MM/YYYY')}` + 
             ` à `+ 
             `${moment().format('DD/MM/YYYY')}`;
    formatDate = `${moment().startOf('month').add(-5, 'month').format('DD/MM/YYYY')}` + 
             ` à `+ 
             `${moment().format('DD/MM/YYYY')}`
  }
  return formatDate;
  
}

const dataPickerOptions = computed(() => {
  let localeOptions;
  if(props.locale == 'pt'){
    localeOptions = ptBR;
  }else if(props.locale == 'en'){
    localeOptions = enUS;
  }else{
    localeOptions = es;
  }

  let placeholderOption = placeholder.value != null ? 
        placeholder.value : 
        `${moment().startOf('month').add(-5, 'month').format('DD/MM/YYYY')}` + 
             ` à `+ 
             `${moment().format('DD/MM/YYYY')}`;
  let monthPicker = props.pickerMode === 'month';
  let autoApply = props.pickerMode === 'week' ? true : props.autoApply;

  let range = props.range;
  if(props.pickerMode === 'week'){
    range = {
      autoRange: 6
    }
  }else if(props.pickerMode === 'semester'){
    range = true;
  }
  let options = {
    formatLocale: localeOptions,
    clearable: false,
    locale: props.locale,
    position: 'left',
    selectText: 'Selecionar',
    cancelText: 'Cancelar',
    placeholder: placeholderOption,
    hideNavigation: ['time'],
    range: range,
    autoApply: autoApply,
    multiCalendars: props.multiCalendars,
    monthPicker: monthPicker,
    maxDate: new Date()
  };
  return options;
});

</script>
<style>
.dp__calendar_header_item{
  font-size: .75rem;
}
</style>