<template>
    <div class="row g-0 align-items-center">
        <div class="col">
            <div 
                class="progress" 
                :class="props.statusBarClass"
                role="progressbar" 
                :aria-valuenow="props.statusBarValue" 
                aria-valuemin="0" 
                aria-valuemax="100">
        
                <div class="progress-bar" :style="{'width': props.statusBarValue+'%'}"></div>
            </div>
        </div>
        <div class="col ms-1">
            <div class="progress-label">
                {{ props.statusBarValue.toFixed(2)+'%' }}
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
const props = defineProps({
    statusBarValue: { type: Number, required: true },
    statusBarClass: { type: String, required: false },
})
</script>
<style scoped>
.progress{
    background: rgba(0,0,0,.25);
    height: .25rem;    
}
.progress.success>div.progress-bar{
    background: rgb(66,144,6);
    background: -moz-linear-gradient(90deg, rgba(66,144,6,1) 0%, rgba(97,191,26,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(66,144,6,1) 0%, rgba(97,191,26,1) 100%);
    background: linear-gradient(90deg, rgba(66,144,6,1) 0%, rgba(97,191,26,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#429006",endColorstr="#61bf1a",GradientType=1);
}
.progress.warning>div.progress-bar{
    background: rgb(239,173,3);
    background: -moz-linear-gradient(90deg, rgba(239,173,3,1) 0%, rgba(250,180,0,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(239,173,3,1) 0%, rgba(250,180,0,1) 100%);
    background: linear-gradient(90deg, rgba(239,173,3,1) 0%, rgba(250,180,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#efad03",endColorstr="#fab400",GradientType=1);
}
.progress.danger>div.progress-bar{
    background: rgb(217,0,0);
    background: -moz-linear-gradient(90deg, rgba(217,0,0,1) 0%, rgba(255,0,0,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(217,0,0,1) 0%, rgba(255,0,0,1) 100%);
    background: linear-gradient(90deg, rgba(217,0,0,1) 0%, rgba(255,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d90000",endColorstr="#ff0000",GradientType=1);
}
</style>