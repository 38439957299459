<template>
    <div class="loader">
      <div class="loader-overlay"></div>
      <div class="loader-container">
         <div class="loader-spinner loader-dot-spinner">
            <div class="loader-dot-spinner-bounce1"></div>
            <div class="loader-dot-spinner-bounce2"></div>
            <div class="loader-dot-spinner-bounce3"></div>
         </div>
         <div class="loader-title">
            Aguarde, estamos carregando os dados.
         </div>
      </div>     
   </div>
</template>
<style scoped>
.loader{
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.loader-overlay{
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   z-index: 99;
   background: rgba(255, 255, 255, .95);
}
.loader-container{
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   z-index: 100;
}
.loader-title {
   text-align: center;
}
.loader-dot-spinner {
   margin: 1rem auto;
   width: 70px;
   text-align: center;
}

.loader-dot-spinner > div {
   width: 18px;
   height: 18px;
   background-color: rgba(79, 0, 125, 1);

   border-radius: 100%;
   display: inline-block;
   -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
   animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.loader-dot-spinner .loader-dot-spinner-bounce1 {
   -webkit-animation-delay: -0.32s;
   animation-delay: -0.32s;
}

.loader-dot-spinner .loader-dot-spinner-bounce2 {
   -webkit-animation-delay: -0.16s;
   animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
   0%, 80%, 100% { -webkit-transform: scale(0) }
   40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
   0%, 80%, 100% {
   -webkit-transform: scale(0);
   transform: scale(0);
   } 40% {
   -webkit-transform: scale(1.0);
   transform: scale(1.0);
   }
}
</style>
