<template>
    <div class="multi-select-wrapper" :class="[props.label, {'active': isActive}]">
        <div class="multi-select-header" ref="multiSelectRef" @click="toggleActive">
            <span class="multi-select-title">{{ props.label }}</span>
            <span class="multi-select-label" :class="{'active': selectedOptions.length > 0}">
                {{selectedOptions.length}}x
            </span>
            <material-icon 
                icon="arrow_drop_down" 
                v-if="!isActive">
            </material-icon>
            <material-icon 
                icon="arrow_drop_up" 
                v-if="isActive">
            </material-icon>
        </div>
        <div class="multi-select-body" v-if="isActive">
            <input v-model="searchInput" type="text" placeholder="Localizar..." class="multi-select-search">
            <div class="multi-select-list">
                <div class="multi-select-list-header">
                    <div class="form-check">
                        <input 
                            class="form-check-input" 
                            type="checkbox" 
                            value="" 
                            id="flexCheckDefault" 
                            @change="toggleAll"
                        >
                        <label class="form-check-label" for="flexCheckDefault">
                            {{ props.label }}
                        </label>
                        <material-icon icon="arrow_downward" v-if="orderBY == 'asc'" @click="orderData">
                        </material-icon>
                        <material-icon icon="arrow_upward" v-if="orderBY == 'desc'" @click="orderData">
                        </material-icon>
                    </div>
                </div>
                <PerfectScrollbar class="multi-select-list-options" v-if="filteredData.length > 0" ref="customMultiselectPerfectScrollbar">
                    <div class="form-check" v-for="item, index in filteredData" :key="index">
                        <input 
                            class="form-check-input" 
                            type="checkbox" 
                            name="selected[]"
                            :value="item[props.param]" 
                            v-model="selectedOptions"
                            :id="'multiselectCheck'+props.label+index" 
                            >
                        <label class="form-check-label" :for="'multiselectCheck'+props.label+index">
                            {{  item[props.option] }}
                        </label>
                    </div>
                </PerfectScrollbar>
                <div class="multi-select-list-options" v-else>
                    <div class="form-check">
                        <label class="form-check-label" for="flexCheckDefault">
                            Sem dados
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </template>
 <script setup>
    import 'vue3-perfect-scrollbar';
    import { default as MaterialIcon } from "./material-icon.vue";
    import { computed, onBeforeUnmount, ref, watch } from "vue";

    const isActive = ref(false);
    const searchInput = ref(null);
    const customMultiselectPerfectScrollbar = ref(null);

    watch(() => searchInput.value, (newValue) => {
        if (newValue && customMultiselectPerfectScrollbar.value) {
            customMultiselectPerfectScrollbar.value.ps.update();
        }
    });

    
    const orderBY = ref('asc');
    const multiSelectRef = ref(null);
    const props = defineProps({
        data: {
            type: Array,
            required: true
        },
        option: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true,
            default: ''
        },
        param: {
            type: String,
            required: false,
            default: 'id'
        },
        type: {
            type: String,
            required: false,
            default: []
        }
    });
    const selectedOptions = ref([]);
    const emit = defineEmits(['selectedOptions', 'doSearch']);

    const isSearcheable = ref(false);

    const handleClickOutside = (event) => {
        let isInside = false;
        let search = true;
        let parent = event.target
        while (search) {
            if (parent && parent.classList) {
                if(parent.classList.value.includes('multi-select-wrapper '+ props.label)){
                    isInside = true;
                    search = false;
                }else{
                    parent = parent.parentElement;
                }
            } else {
                isInside = false;
                search = false;
            }
        }
        removeClickOutsideListener(isInside);
    };
    
    const addClickOutsideListener = () => {
        document.addEventListener('click', handleClickOutside);
    };

    const removeClickOutsideListener = (isInside) => {
        if(!isInside){
            document.removeEventListener('click', handleClickOutside);
            isActive.value = false;
            if(isSearcheable.value === true){
                emit('doSearch');
                searchInput.value = null;
                isSearcheable.value = false;
            }
            
        }
    };

    const toggleActive = () => {
        if(!isActive.value){
            addClickOutsideListener();
            isActive.value = true;
        }else{
            removeClickOutsideListener(false);
        }
    }
    
    onBeforeUnmount(() => {
        removeClickOutsideListener();
    });
    
    watch(() => props.type, (newValue, oldValue) => {
        if(newValue == 'drivers'){
            if(selectedOptions.value && selectedOptions.value.length > 0){
                let change = [];
                selectedOptions.value.forEach((item,index) => {
                    let motoristaDesconhecido = props.data.filter(dataItem => dataItem.id == item);
                    if(motoristaDesconhecido[0].name.indexOf('Motorista Desconhecido') == -1){
                        change.push(item);
                    }
                })
                selectedOptions.value = [ ...change];
                emit('selectedOptions', selectedOptions.value)
            }
        }
    })

    watch(() => selectedOptions.value, (newValue, oldValue) => {
        console.log(newValue, oldValue);
        emit('selectedOptions', selectedOptions.value)
        if(oldValue != newValue || (oldValue.length == 0 && newValue.length > 0)){
            isSearcheable.value = true;
        }else{
            isSearcheable.value = false;
        }
    })

    const toggleAll = () => {
        if(selectedOptions.value.length < props.data.length){
            selectedOptions.value = [];
            props.data.forEach((item) => {
                selectedOptions.value.push(item.id)
            });
        }else{
            selectedOptions.value = [];
        }
    }
        
    const filteredData = computed(() => {
        let result = [];
        if(searchInput.value !== null){
            result = [ ...props.data.filter((item) => {
                
                if(item[props.option].toLowerCase().indexOf(searchInput.value.toLowerCase()) > -1){
                    return item;
                }
            })];
        }else{
            result = [ ...props.data];
        }
        return result;
    })

    const orderData = () => {
        if(orderBY.value == 'asc'){
            props.data.value = [ ...props.data].sort((a, b) => a.option.localeCompare(b.option));
            orderBY.value = 'desc';
        }else{
            props.data.value = [ ...props.data].sort((a, b) => b.option.localeCompare(a.option));
            orderBY.value = 'asc';
        }
    }
    
    
 </script>
 <style>
    .multi-select-wrapper {
        max-width: 300px;
        font-size: 16px;
        position: relative;
    }
    .multi-select-title,
    .multi-select-wrapper,
    .multi-select-body,
    .multi-select-search {
        font-family: inherit;
    }
    .multi-select-header{
        width: 100%;
        display: flex;
        cursor: pointer;
        background: #fff;
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        
        font-weight: 700;
        color: rgba(49, 49, 49, 1);
        padding: 0.75rem 1rem;
        border: 3px solid rgba(232, 232, 232, 1);
    
    }
    .multi-select-header span.material-icons{
        margin-left: auto;
    }
    .multi-select-body{
        position: absolute;
        top: 120%;
        right: 0;
        left: auto;
        background: #fff;
        width: fit-content;
        min-width: 100%;
        border-radius: .5rem;
        z-index: 150;
    }
    .multi-select-search{
        padding: 0.5rem;
        outline: none black;
        border-radius: .25rem;
        width: 100%;
        border: 1px solid rgb(229, 231, 235);
        margin-bottom: .5rem;
    }

    .multi-select-list{
        border: 1px solid rgb(186, 191, 199);
        border-radius: .25rem;
        white-space: nowrap !important;
        overflow: hidden;
    }
    .multi-select-list-header label{
        cursor: pointer;
    }

    .multi-select-list-header{
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 3rem;
        background: #f8f8f8;
        
    }

    .multi-select-list-options{
        border-top:  1px solid rgb(186, 191, 199);
        font-size: 0.8125rem;
        line-height: 3rem;
        font-weight: 400;
        font-size: 0.875rem;
        max-height: 400px;
    }

    .multi-select-list-options .form-check:hover{
        background: rgba(33, 150, 243, 0.1);
    }

    .multi-select-list .form-check{
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0 1.5rem;
    }
    .multi-select-list .form-check .form-check-input{
        margin-right: 1.125rem;
        margin-top: 0;
        float: none;
        width: 1rem;
        height: 1rem;
        outline: none !important;
        box-shadow: none !important;
        position: relative;
        background: #FFF;
        transition: all .3s;
        margin-left: 0;
        border: 3px solid #999999;
    }
    .form-check-input:checked[type=checkbox]{
        background: #2196f3;
        border: 0px;
    }

    .form-check-input:checked[type=checkbox]::before {
        content: '\e876';
        font-family: 'Material Icons';
        position: absolute;
        top: 0;
        line-height: 1rem;
        color: #fff;
        font-size: 1rem;
    }
    .multi-select-list-header span.material-icons{
        font-size: .75rem;
        margin-left: .25rem;
        vertical-align: middle;
        cursor: pointer;
    }
    .multi-select-body{
        max-height: 0px;
        height: fit-content;    
        overflow: hidden;
        padding: 0px;
        opacity: 0;
        transition: opacity .3s;
    }
    .multi-select-wrapper.active .multi-select-body{
        padding: 1rem;
        max-height: initial;
        opacity: 1;
    }

    .multi-select-label{
        background: rgb(79, 0, 125);
        position: absolute;
        right: 2.75rem;
        color: #fff;
        padding: .5rem;
        border-radius: .25rem;
        text-align: center;
        line-height: .75rem;
        font-size: .75rem;
        top: 50%;
        transform: translate(0, -50%);
        opacity: 0;
        transition: opacity .3s;
    }
    .multi-select-label.active{
        opacity: 1;
    }
</style>