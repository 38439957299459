<template>
    <multiselect 
        :modelValue="value"
        :options="props.list"
        :searchable="true" 
        :multiple="props.multiple" 
        :close-on-select="!props.multiple" 
        :show-labels="false"
        :preserve-search="false" 
        :label="props.label"
        :track-by="props.label"
        :placeholder="props.placeholder"
        :loading="props.loading"
        :disabled="props.disabled"
        :show-no-options="false"
        @update:modelValue="sendEmits"
        @remove="sendEmitsRemoval"
        @close="sendEmitsOther"
    >
    </multiselect>
</template>
<script setup lang="ts">
    import { ref, defineEmits, watch, computed } from 'vue';
    import Multiselect from 'vue-multiselect'

    const value = ref([])

    const emits = defineEmits(['selectedId']);

    const sendEmits = (evt: any) => {
        value.value = evt;
        if(!props.multiple){
            emits('selectedId', value.value)
        }
    }
    const sendEmitsRemoval =  (evt: any) => {
        emits('selectedId', value.value)
    }
    const counter = ref(0);

    const sendEmitsOther = (evt: any) => {
        value.value = evt;
        if(props.multiple){
            emits('selectedId', value.value)
        }
    }

    const clearAll = computed(() => props.disabled ? 1 : 0);
    
    const props = defineProps({
        list: { type: Array, required: true },
        placeholder: {type: String, required: true},
        label: {type: String, required: true},
        multiple: {type: Boolean, required: true},
        loading: {type: Boolean, required: true},
        disabled: {type: Boolean, required: true},
    })

    watch(props, (oldValue, newValue) => {
        if(newValue.disabled){
            value.value = [];
        }
    })
</script>