<template>
    <span>
        {{ text }}
    </span>
</template>
<script setup lang="ts">
import { Tooltip } from 'bootstrap'
import { computed, onMounted } from 'vue';

onMounted(() => {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
})

const props = defineProps({
    text: { type: String, required: true },
    tooltip: { type: String, required: true },
})

const tooltipRef = computed(() => props.tooltip)
</script>