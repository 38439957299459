<template>
    <span class="material-icons" :class="props.iconClass">{{ props.icon }}</span>
</template>
<script setup lang="ts">

const props = defineProps({
    icon: { type: String, required: true },
    iconClass: { type: String, required: false },
})

</script>