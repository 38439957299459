// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.friendly-notification[data-v-6680f6bc]{
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    z-index: 101;
    max-width: 350px;
    padding: 1rem;
    border-radius: .5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    box-shadow: -.125rem -.125rem .5rem .125rem rgba(0, 0, 0, .125);
    transition: all .3s;
}
@media screen and (max-width: 991px){
.friendly-notification[data-v-6680f6bc]{
        bottom: 1rem;
        left: 50%;
        font-size: 1rem;
        line-height: 1.3rem;
        transform: translate(-50%, 0);
        width: 90%;
        box-shadow: 0 1rem .5rem .125rem rgba(0, 0, 0, .5);
        text-align: center;
}
}
.friendly-notification.success[data-v-6680f6bc]{
    background-color: #27ae60;
}
.friendly-notification.warning[data-v-6680f6bc]{
    background-color: #f39c12;
}
.friendly-notification.error[data-v-6680f6bc]{
    background-color: #c0392b;
}
`, "",{"version":3,"sources":["webpack://./src/component-library/misc/friendly-notification.vue"],"names":[],"mappings":";AAcA;IACI,eAAe;IACf,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,oBAAoB;IACpB,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,+DAA+D;IAC/D,mBAAmB;AACvB;AACA;AACI;QACI,YAAY;QACZ,SAAS;QACT,eAAe;QACf,mBAAmB;QACnB,6BAA6B;QAC7B,UAAU;QACV,kDAAkD;QAClD,kBAAkB;AACtB;AACJ;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B","sourcesContent":["<template>\n    <div class=\"friendly-notification\" :class=\"[props.options.type]\">\n        <span>\n            {{ options.text }}\n        </span>\n    </div>\n</template>\n<script setup lang=\"ts\">\n\nconst props = defineProps({\n    options: { type: Object, required: true },\n})\n</script>\n<style scoped>\n.friendly-notification{\n    position: fixed;\n    bottom: 2rem;\n    left: 2rem;\n    z-index: 101;\n    max-width: 350px;\n    padding: 1rem;\n    border-radius: .5rem;\n    color: #fff;\n    font-size: 1rem;\n    font-weight: 400;\n    text-align: left;\n    box-shadow: -.125rem -.125rem .5rem .125rem rgba(0, 0, 0, .125);\n    transition: all .3s;\n}\n@media screen and (max-width: 991px){\n    .friendly-notification{\n        bottom: 1rem;\n        left: 50%;\n        font-size: 1rem;\n        line-height: 1.3rem;\n        transform: translate(-50%, 0);\n        width: 90%;\n        box-shadow: 0 1rem .5rem .125rem rgba(0, 0, 0, .5);\n        text-align: center;\n    }\n}\n.friendly-notification.success{\n    background-color: #27ae60;\n}\n.friendly-notification.warning{\n    background-color: #f39c12;\n}\n.friendly-notification.error{\n    background-color: #c0392b;\n}\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
