<template>
    <font-awesome-icon :icon="`fa-${props.icon}`" :class="iconClass"  />
</template>
<script setup lang="ts">

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFileExcel, faFilePdf, faRotate } from "@fortawesome/free-solid-svg-icons";
library.add([faFileExcel, faFilePdf, faRotate] );

const props = defineProps({
    icon: { type: String, required: true },
    iconClass: { type: String, required: false },
})

</script>