<template>
    <div class="dashboard-card">
        <div class="dashboard-card-header translate">
            {{cardHeader}}
        </div>
        <div class="dashboard-card-data">
            {{cardData}}
        </div>
        <div class="dashboard-card-info">
            <material-icon 
                icon="info" 
                v-if="cardInfo" 
                data-bs-toggle="tooltip" 
                data-bs-placement="bottom"
                data-bs-custom-class="dashboard-tooltip"
                :data-bs-title="cardInfo"
                class="notranslate"
            ></material-icon>
        </div>
        <div class="dashboard-percentage">
            {{cardPercentage ? cardPercentage?.toString().replace('.',',')+'%' : ''}}
        </div>
    </div>
</template>
<script setup lang="ts">
import { Tooltip } from 'bootstrap'

import materialIcon from '../misc/material-icon.vue';
import { onMounted } from 'vue';

onMounted(() => {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
})

const props = defineProps({
    cardHeader: { type: String, required: true },
    cardData: { type: String, required: true },
    cardInfo: { type: String, required: false },
    cardPercentage: { type: String, required: false },
})
</script>